@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  animation: marquee 20s linear infinite;
}

/* Adjust the image spacing */
.animate-marquee > * {
  margin-right: 20px;
}
