@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100&family=Inter:wght@100;300;400;500;600;700;800&display=swap");

@layer base {
  html {
    font-family: "Raleway", sans-serif, "DM+Sans";
  }
}

@font-face {
  font-family: "sansation";
  src: url(./Assets/sansation/Sansation_Bold.ttf);
  font-weight: 600;
}

.font-sansation {
  font-family: "sansation";
}
@font-face {
  font-family: "Satoshi";
  src: url(./Assets/Satoshi_Complete/Satoshi_Complete/Fonts/OTF/Satoshi-Black.otf);
  font-weight: 600;
}

.font-sansation {
  font-family: "Satoshi";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.collapse.navbar-collapse {
  visibility: visible !important;
}

@media (max-width: 640px) {
  .grid-cols-3 {
    grid-template-columns: 1fr;
  }
}

/* body {
  background-image: url("../../sub-agents-frontend//src//Assets/HsixRectangle.png");
  background-size: cover;
  background-repeat: no-repeat;
} */
